import { Table, Tag, Space } from 'antd'
import { BarChart, GridlineSeries, Gridline } from 'reaviz'
import { useSelector, useDispatch } from 'react-redux'
import React from 'react'

const StudentGradeBook = ({ courseId, id }) => {
  const data = useSelector(
    (state) => state.summaryGradebook.filter((val) => val.student._id === id)[0]
  )

  const columns = [
    {
      title: '유형',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: '이름',
      dataIndex: 'title',
      key: 'name'
    },
    {
      title: '점수',
      dataIndex: 'score',
      key: 'score',
      sorter: {
        compare: (a, b) => a.score - b.score
      }
    },
    {
      title: '최고점수',
      dataIndex: 'maxScore',
      key: 'maxScore',
      // sorter: {
      //   compare: (a, b) => a.maxScore - b.maxScore
      // }
    },
    // {
    //   title: '중요도',
    //   dataIndex: 'weight',
    //   key: 'weight',
    //   sorter: {
    //     compare: (a, b) => Number(a.weight) - Number(b.weight)
    //   },
    //   render: (text) => <span>{String(text * 100) + '%'}</span>
    // },
    // {
    //   title: '채점시각',
    //   key: 'gradedAt',
    //   sorter: {
    //     compare: (a, b) => new Date(a.gradedAt) - new Date(b.gradedAt)
    //   },
    //   render: (text, record) => (
    //     <Space size="middle">
    //       {new Date(record.gradedAt).toLocaleString()}
    //     </Space>
    //   )
    // }
  ]

  const fCol = [
    {
      title: '과제',
      dataIndex: 'assignmentsScore',
      key: 'assignmentsScore'
    },
    {
      title: '시험',
      dataIndex: 'examsScore',
      key: 'examsScore'
    },
    /*{
      title: 'Final Exam',
      dataIndex: 'finalExamsScore',
      key: 'finalExamsScore'
    },*/
    {
      title: '최고점수',
      dataIndex: 'totalScore',
      key: 'totalScore'
    },
    {
      title: '평가',
      dataIndex: 'grade',
      key: 'grade'
    }
  ]

  return (
    <div>
        <BarChart
        width={'100%'}
        height={300}
        data={data?.grades
          ?.map((v) => {
            let res = {
              key: v.title,
              data: (v.score / v.maxScore) * 10
            }
            return res
          })
          .sort(function (a, b) {
            return b.data - a.data
          })}
        gridlines={<GridlineSeries line={<Gridline direction="x" />} />}
      />
      <Table
        style={{marginTop : '20px'}}
        rowKey={(record) => record?.id}
        columns={columns}
        dataSource={data?.grades.map((item) => {
          if (item.type === 'Assignment') {
            return {...item, type : '과제'}
          }
          if (item.type === 'Exam') {
            return {...item, type : '시험'}
          }
          return item
        })}
        bordered
        title={() => {
          return '나의 성적부'
        }}
        footer={() => {
          return (
            <></>
            // <Table
            //   rowKey={(record) => record?.id}
            //   columns={fCol}
            //   dataSource={[data]}
            //   bordered
            //   title={() => '요약'}
            //   pagination={false}
            // />
          )
        }}
        pagination={false}
      />
    
    </div>
  )
}

export default StudentGradeBook
