import React from 'react'

import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { Button, Dropdown, Menu, Space } from 'antd'
import { Link, NavLink, useRouteMatch } from 'react-router-dom'

import { DownOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import useCoursePrivilege from '../../hooks/useCourseprivilege'

  
const CourseMenu = ({ url, privilege }) => {


  const isMobile = useMediaQuery({ query: "(max-width : 473px)" })
  
  // const items = [
  //   {
  //     label: <NavLink to={`${url}/announcments`}>공지사항</NavLink>,
  //     key: `${url}/announcments`
  //   },
  //   {
  //     label: <NavLink to={`${url}/gradebook`}>성적표</NavLink>,
  //     key: `${url}/gradebook`
  //   },
  //   {
  //     label: <NavLink to={`${url}/discussions`}>토론</NavLink>,
  //     key: `${url}/discussions`
  //   },
  //   {
  //     label: <NavLink to={`${url}/particpants`}>참가자</NavLink>,
  //     key: `${url}/particpants`
  //   }

  // ]
  
  return (
    <Menu >
      {isMobile && (
        <>
          <Menu.Item>
          <Link to={`${url}/modules`}>목차</Link>
          </Menu.Item>
          <Menu.Item>
          <Link to={`${url}/lectures`}>강의</Link>
          </Menu.Item>
          <Menu.Item>
          <Link to={`${url}/assignments`}>과제</Link>
          </Menu.Item>
          <Menu.Item>
          <Link to={`${url}/exams`}>시험</Link>
        </Menu.Item>
        </>
      )}
      <Menu.Item>
        <Link to={`${url}/announcments`}>공지사항</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={`${url}/gradebook`}>성적표</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={`${url}/discussions`}>토론</Link>
      </Menu.Item>
        <Menu.Item>
          <Link to={`${url}/particpants`}>참가자</Link>
        </Menu.Item>

      {privilege !== 'student' && (
        <Menu.Item>
          <Link to={`${url}/settings`}>환경설정</Link>
        </Menu.Item>
      )}
    </Menu>
  )
}

const CourseNavigation = () => {
  const { params, url } = useRouteMatch('/app/course/:id')

  const history = useHistory()
  const isNotMobile = useMediaQuery({ query: "(min-width : 473px)" })

  let course = useSelector((state) =>
    state.courses.data.find((course) => course.id === params.id)
  )

  const { privilege } = useCoursePrivilege(params.id)

  const popHistory = () => {
    history.goBack()
  }

  return (
    <>
      <Space>
        {/* <Button
          shape="circle"
          type="secondary"
          onClick={popHistory}
          icon={<ArrowLeftOutlined />}
        ></Button> */}
        <Dropdown
          trigger={['click']}
          overlay={<CourseMenu url={url} privilege={privilege} />}
          // placement="bottomCenter"
        >
          <Button
            shape="square"
            style={{ backgroundColor: 'black' }}
          >
            <span style={{ fontWeight: 600, color: 'white' }}>
              {course.name}
            </span>{' '}
            <DownOutlined style={{ color: 'white' }} />
          </Button>
        </Dropdown>
      </Space>
      {isNotMobile && (
        <>
          <NavLink to={`${url}/modules`}>
        <Button type="text">목차</Button>
      </NavLink>
      <NavLink to={`${url}/lectures`}>
        <Button type="text">강의</Button>
      </NavLink>
      <NavLink to={`${url}/assignments`}>
        <Button type="text">과제</Button>
      </NavLink>
      <NavLink to={`${url}/exams`}>
        <Button type="text">시험</Button>
      </NavLink>
        </>
      ) 
      }
      {/* <NavLink to={`${url}/exam/12345`} target="_blank">
        <Button type="text">CheatingDetection</Button>
      </NavLink> */}
    </>
  )
}

export default CourseNavigation
