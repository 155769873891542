import React from 'react'

import {  useLocation, useHistory } from 'react-router-dom'
import { ImBooks } from 'react-icons/im'
import { AiTwotoneSchedule } from "react-icons/ai";

import styled from 'styled-components'

const FlexedDiv = styled.div`
  display: flex;
  justify-content: space-around;
  position : fixed;
  bottom: 0px;
  height : 50px;
  width : 100%;
  align-items: center;
  padding: 0 32px;
  background: #fff;
  box-shadow: 0px -5px 20px #c9d6dc;
  font-size : 16px;
  @media screen and (min-width : 768px) {
    display : none;
  }

`

const NavItem = styled.div`
  color: ${(props) => (props.isActive ? 'blue' : 'black')};

  &:hover {
  cursor: pointer;
  }
`;

const BottomNav = () => {

  let currentPath = useLocation().pathname
  const history = useHistory()

  const navRouteCourses = () => {
    history.push('/app/courses')
  }

   const navRouteDashboard = () => {
    history.push('/app/dashboard')
  }

  return (
    <FlexedDiv>
       <NavItem onClick={navRouteDashboard} isActive={currentPath === '/app/dashboard'}>
        <ImBooks />&nbsp;등록과목
      </NavItem>
      <NavItem onClick={navRouteCourses} isActive={currentPath === '/app/courses'}>
        <AiTwotoneSchedule />&nbsp;개설과목
      </NavItem>
    </FlexedDiv>
  )
}

export default BottomNav
