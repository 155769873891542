import React from 'react'
import { Result } from 'antd'

const NotFound = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="해당 페이지는 존재하지 않습니다."
    />
  )
}

export default NotFound
