import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { login } from '../../reducers/authReducer'

import { Form, Input, Button, Checkbox, Divider, Modal } from 'antd'
import { MailOutlined, LockOutlined, GoogleOutlined } from '@ant-design/icons'

import {
  FormItemFlex,
  PageContainer,
  FormContainer,
  FormWrapper,
  FormTitle
} from './style'

const Login = () => {
  const dispatch = useDispatch()

  const onFinish = (values) => {
    console.log("values", values)
    if (values.remember) {
      window.localStorage.setItem(
        'thecoding-remember',
        JSON.stringify({ remember: values.remember, email: values.email })
      )
    } else {
      window.localStorage.setItem(
        'thecoding-remember',
        JSON.stringify({ remember: false })
      )
    }
    dispatch(login(values))
  }

  const getInitialValues = () => {
    return JSON.parse(window.localStorage.getItem('thecoding-remember'))
  }

  const findId = () => {
      Modal.confirm({
        title: '계정이 기억 안나시는군요?',
        content: [
          <div key="1">해당 기능은 현재 개발중에 있습니다. 빠른 시일내에 완료하도록 하겠습니다.</div>,
          <div key="2">
            <a href='https://open.kakao.com/o/sqb0EI2f' target='_blank' rel="noopener noreferrer">급하시면 카카오 오픈채팅 상담 바로가기 클릭!</a>
          </div>
        ],
        okText: '확인',
        cancelText: '',
        cancelButtonProps : {style : {display : 'none'}}
  })
  }
  
  return (
    <PageContainer>
      <FormContainer>
        <FormWrapper>
          <Form
            name="login"
            onFinish={onFinish}
            initialValues={getInitialValues()}
            scrollToFirstError
          >
            <FormTitle>로그인</FormTitle>

            <p style={{marginBottom : '16px', marginTop : '12px'}}>
              처음이세요? <Link to="/register">회원가입 하러가기</Link>
            </p>
            <p style={{marginBottom : '16px', marginTop : '12px'}}>
              테스트 임시계정:test@gmail.com/test12!@
            </p>

            <Form.Item
              name="email"
              rules={[
                {
                  type: 'email',
                  message: '이메일 형식이 틀렸어요!'
                },
                {
                  required: true,
                  message: '이메일을 입력해 주세요!'
                }
              ]}
            >
              <Input prefix={<MailOutlined />} placeholder="이메일" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: '비밀번호를 입력해주세요!'
                }
              ]}
              hasFeedback
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="비밀번호"
              />
            </Form.Item>

            <FormItemFlex>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>이메일 기억하기</Checkbox>
              </Form.Item>
              {/* <Link to="/ForgetPassword">Forget Password</Link> */}
            </FormItemFlex>

            <Form.Item>
              <Button block type="primary" htmlType="submit">
                로그인
              </Button>
            </Form.Item>
             <p style={{marginBottom : '16px', marginTop : '12px'}}>
              계정 기억이 안나세요? <span onClick={findId} style={{ color: '#1677ff', cursor: 'pointer' }}>아이디 찾기 또는 비밀번호 변경</span>
            </p>

            {/* <Divider plain>Or</Divider>

            <Form.Item>
              <Button block icon={<GoogleOutlined />}>
                Sign in with Google
              </Button>
            </Form.Item> */}
          </Form>
        </FormWrapper>
      </FormContainer>
    </PageContainer>
  )
}
export default Login
