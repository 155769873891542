import assessmentsService from '../services/assessments'

import { notification } from 'antd'

const LOAD_EXAMS = 'LOAD_EXAMS'
const GET_ALL_EXAMS = 'GET_ALL_EXAMS'
const DELETE_EXAM = 'DELETE_EXAM'

const examReducer = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case LOAD_EXAMS:
      return { data: [], loading: true }
    case GET_ALL_EXAMS:
      return { data: action.data, loading: false }
    case DELETE_EXAM:
      return {
        data: state.data.filter((exam) => exam.id !== action.examId),
        loading: false
      }
    default:
      return state
  }
}

export const getAllExams = (courseId, userId, role) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_EXAMS })
      const response = await assessmentsService.getAllExams(
        courseId,
        userId,
        role
      )

      dispatch({ type: GET_ALL_EXAMS, data: response })
    } catch (error) {
      console.log(error)
      notification.error({
        message: '시험을 가져올 수 없습니다. 통신을 확인해주세요.'
      })
    }
  }
}

export const deleteExam = (courseId, assessmentId) => {
  return async (dispatch) => {
    try {
      await assessmentsService.deleteAssessment(courseId, assessmentId)

      dispatch({ type: DELETE_EXAM, examId: assessmentId })
      // notification.success({
      //   message: '시험을 삭제했습니다.',
      //   placement: 'bottomRight'
      // })
    } catch (error) {
      console.log(error)
      notification.error({
        message: '시험을 삭제할 수 없습니다.'
      })
    }
  }
}

export default examReducer
