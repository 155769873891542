import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { logout } from '../../reducers/authReducer'
import usersService from '../../services/users'
const PrivateRoute = ({ ...routeProps }) => {
  const dispatch = useDispatch()
  const { isAuth } = useSelector((state) => state.auth)
  const user = useSelector((state) => state?.auth?.user)


  // 회원탈퇴 계정은 로그아웃처리
  useEffect(() => {
    const resultPromise = usersService.getUser()
    resultPromise.then((result) => {
      console.log('result', result)
      if (result?.isDeleted) {
      dispatch(logout())
      }
    }).catch((error) => {
      console.log(error)
    })
    }, [])
  
  return (
    <>
      {!isAuth && <Redirect to="/login" />}

      {isAuth && <Route {...routeProps} />}
    </>
  )
}

export default PrivateRoute
