import React from 'react'

import { NavLink, useLocation, useHistory } from 'react-router-dom'
import { Space, Menu } from 'antd'
import ProfileIcon from '../ProfileIcon'
import DropDownNotification from '../DropDownNotification'
import CourseNavigation from '../CourseNavigation'
import styled from 'styled-components'
import Logo from '../Logo'
const FlexedDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 32px;
  background: #fff;
  box-shadow: 0px -5px 20px #c9d6dc;
`

const NavItem = styled.div`
  color: ${(props) => (props.isActive ? 'blue' : 'black')};
  font-size : 16px;
  font-weight: 500;
  &:hover {
  cursor: pointer;
  }

  margin-right : 24px;

  @media screen and (max-width: 768px) {
     display : none
  }
  @media screen and (min-width: 768px) {
     display : block
  }
`;

const items = [
  {
    label: <NavLink to="/app/dashboard">등록과목</NavLink>,
    key : "/app/dashboard"
  },
  {
    label: <NavLink to="/app/courses">개설과목</NavLink>,
    key : "/app/courses"
  }
]


const AppHeader = ({ courseNavigation }) => {

  let currentPath = useLocation().pathname
 const history = useHistory()
    // dummy fix for side nav highlight
  if (currentPath.includes('/app/course/'))
    currentPath = currentPath.replace('/app/course/', '/app/courses/')

    const navRouteCourses = () => {
    history.push('/app/courses')
  }

   const navRouteDashboard = () => {
    history.push('/app/dashboard')
   }
  
  return (
    <FlexedDiv>
        
      <Space>{courseNavigation ? <CourseNavigation /> : <Logo/>}</Space>
      <Space>
        {/* <Menu selectedKeys={currentPath} mode="horizontal" items={items} style={{ border: "none" }} className='navHeader' /> */}
          <NavItem onClick={navRouteDashboard} isActive={currentPath === '/app/dashboard'}>
        등록과목
      </NavItem>
      <NavItem onClick={navRouteCourses} isActive={currentPath === '/app/courses'}>
        개설과목
        </NavItem>
        {/* <DropDownNotification /> */}
        <ProfileIcon />
      </Space>
    </FlexedDiv>
  )
}

export default AppHeader
