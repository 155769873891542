import discussionService from '../services/discussion'
import {
  GET_ALL_DISCUSSIONS,
  ADD_DISCUSSION,
  REMOVE_DISCUSSION,
  ADD_DCOMMENT,
  REMOVE_DCOMMENT
} from '../actions/discussion'
import checkModerationService from '../services/checkModeration'
import { notification } from 'antd'

const discussionReducer = (state = [], action) => {
  switch (action.type) {
    case GET_ALL_DISCUSSIONS:
      return action.data.reverse()
    case ADD_DISCUSSION:
      return state
        .reverse()
        .concat({ ...action.data })
        .reverse()
    case REMOVE_DISCUSSION:
      return state.filter((val) => {
        return val._id !== action.data
      })
    case ADD_DCOMMENT:
      return state.map((val) => {
        if (val._id === action.data._id) {
          return action.data
        } else {
          return val
        }
      })
    case REMOVE_DCOMMENT:
      return state.map((val) => {
        if (val._id === action.data._id) {
          return action.data
        } else {
          return val
        }
      })
    default:
      return state
  }
}

export const getAllDiscussions = (courseId) => {
  return async (dispatch) => {
    try {
      const response = await discussionService.getAllDiscussions(courseId)
      dispatch({ type: GET_ALL_DISCUSSIONS, data: response })
    } catch (error) {
      console.log(error)
      notification.error({
        message: '토론 내용을 가져올 수 없습니다. 통신을 확인해주세요.',
        placement: 'bottomRight',
        duration: 2
      })
    }
  }
}

export const addDiscussion = (courseId, data) => {
  return async (dispatch) => {
    try {
      const ver = await checkModerationService.check(data)
      if (ver) {
        const response = await discussionService.addDiscussion(courseId, data)
        dispatch({ type: ADD_DISCUSSION, data: response })
        // notification.success({
        //   message: 'Posted successfully'
        // })
      } else {
        notification.error({
          message: '더코딩클럽 정책에 위배됩니다.',
          placement: 'bottomRight',
          duration: 2
        })
      }
    } catch (error) {
      console.log(error)
      notification.error({
        message: '네트워크 통신을 확인해보세요',
        placement: 'bottomRight',
        duration: 2
      })
    }
  }
}

export const removeDiscussion = (id) => {
  return async (dispatch) => {
    try {
      await discussionService.removeDiscussion(id)
      dispatch({ type: REMOVE_DISCUSSION, data: id })
      // notification.success({
      //   message: '성공적으로 삭제했습니다.'
      // })
    } catch (error) {
      console.log(error)
      notification.error({
        message: '네트워크 통신을 확인해보세요',
        placement: 'bottomRight',
        duration: 2
      })
    }
  }
}

export const addComment = (id, comment) => {
  return async (dispatch) => {
    try {
      const ver = await checkModerationService.check(comment)
      if (ver) {
        const response = await discussionService.addComment(id, comment)
        dispatch({ type: ADD_DCOMMENT, data: response })
        // notification.success({
        //   message: 'Comment Posted successfully'
        // })
      } else {
        notification.error({
          message: '당신의 댓글은 더코딩클럽의 정책을 위배합니다.',
          placement: 'bottomRight',
          duration: 2
        })
      }
    } catch (error) {
      console.log(error)
      notification.error({
        message: '댓글을 추가 할 수 없습니다. 통신을 확인해주세요.',
        placement: 'bottomRight',
        duration: 2
      })
    }
  }
}

export const removeComment = (id, comment) => {
  return async (dispatch) => {
    try {
      const response = await discussionService.removeComment(id, comment)
      dispatch({ type: REMOVE_DCOMMENT, data: response })
      // notification.success({
      //   message: 'Comment removed successfully'
      // })
    } catch (error) {
      console.log(error)
      notification.error({
        message: '댓글을 삭제할 수 없습니다. 통신을 확인해 주세요.',
        placement: 'bottomRight',
        duration: 2
      })
    }
  }
}

export default discussionReducer
