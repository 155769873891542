import React from 'react'
import { createRoot } from 'react-dom/client'

import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'

import './index.css'
import App from './App'
import { store, persistor } from './store.js'
import { PersistGate } from 'redux-persist/integration/react'
import { ConfigProvider } from 'antd'
serviceWorker.register()

const root = createRoot(document.getElementById('root'))

const config = {
  token: {
    colorPrimary: '#212529'
  }
}

root.render(
  <ConfigProvider theme={config}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ConfigProvider>
)
