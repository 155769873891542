import {
  Affix,
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Typography
} from 'antd'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import {
  getAllParticipants
} from '../../../reducers/participantsReducer'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../../components/Spinner'

const { Title, Text } = Typography
const { RangePicker } = DatePicker
const { Option } = Select

const AssessmentOptionsMenu = (props) => {
  const [form] = Form.useForm()
  const { courseId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const participants = useSelector((state) => state.courseParticipants.data)
  const loading = useSelector((state) => state.courseParticipants.loading)
  const participantArray = participants.map(item => {
    return {value : item.user._id, label : item.user.name}
  })
  
  const {
    handleSubmit,
    maxScore,
    controlledQuestionType,
    handlecontrolledQuestionType,
    assessmentType
  } = props

    useEffect(() => {
    dispatch(getAllParticipants(courseId))
  }, [courseId, dispatch])

  useEffect(() => {
    form.setFieldsValue({
      maxScore,
      questionsType: controlledQuestionType,
      submissionType:
        controlledQuestionType === 'file'
          ? 'written'
          : form.getFieldValue('submissionType')
    })
  }, [form, maxScore, controlledQuestionType])

  if (loading) return <Spinner size="large" />
  
  console.log("participants", participants)
  console.log("participantArray", participantArray)

  return (
    <div offsetTop={10}>
      <div
        style={{
          backgroundColor: 'white',
          padding: '20px 20px',
          borderRadius: '10px'
        }}
      >
        <Title level={4}>{assessmentType} 옵션</Title>
        <Divider />
        <Form
          form={form}
          onFinish={handleSubmit}
          name="options"
          layout={'vertical'}
        >
          <Form.Item
            name="toStudent"
            style={{ width: '100%' }}
            label={<Text strong>전달받는 학생</Text>}
          >
            <Select
              showSearch
              placeholder="학생 선택"
              options={participantArray}
            >
            </Select>
          </Form.Item>
          <Form.Item
            name="questionsType"
            style={{ width: '100%' }}
            label={<Text strong>질문 유형</Text>}
          >
            <Select onChange={handlecontrolledQuestionType} disabled="true">
              <Option value="online">온라인</Option>
              {/* <Option value="file">파일</Option> */}
            </Select>
          </Form.Item>
          <Form.Item
            initialValue="online"
            name="submissionType"
            style={{ width: '100%' }}
            label={<Text strong>제출 유형</Text>}
          >
            {/* <Select disabled={controlledQuestionType === 'file'}> */}
            <Select disabled="true">
              <Option value="online">온라인</Option>
              {/* <Option value="written">쓰여진 파일</Option> */}
            </Select>
          </Form.Item>
          {assessmentType === 'Exam' && (
            <Form.Item
              name="time"
              label={<Text strong>오픈 마감 시간</Text>}
              rules={[
                {
                  type: 'array',
                  required: true,
                  message: '시간을 선택해야 합니다!'
                }
              ]}
            >
              <RangePicker
                use12Hours
                placeholder={['오픈', '마감']}
                showTime={{
                  format: 'HH:mm:ss'
                }}
                format="YYYY-MM-DD HH:mm:ss"
              />
            </Form.Item>
          )}
          {assessmentType === 'Assignment' && (
            <Form.Item
              name="dueDate"
              style={{ width: '100%' }}
              label={<Text strong>데드라인</Text>}
            >
              <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime placeholder='날짜 선택'/>
            </Form.Item>
          )}
          <Form.Item
            name="weight"
            label={<Text strong>중요도</Text>}
            rules={[{ required: true }]}
          >
            <Input style={{ width: '40%' }} placeholder="0~10" />
          </Form.Item>
          <Form.Item
            name="maxScore"
            style={{ width: '100%', display : "none" }}
            label={<Text strong>최고 점수</Text>}
          >
            <InputNumber disabled={controlledQuestionType === 'online'} />
          </Form.Item>

          <Space style={{ marginTop: '8px' }}>
            <Button
              onClick={() => {
                form.submit()
              }}
              type="primary"
            >
              생성
            </Button>
            <Button
              onClick={() =>
                history.push(
                  `/app/course/${courseId}/${assessmentType.toLowerCase()}s`
                )
              }
            >
              취소
            </Button>
          </Space>
        </Form>
      </div>
    </div>
  )
}

export default AssessmentOptionsMenu
