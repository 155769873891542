import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import usersService from '../../services/users'

import { PlusOutlined, CaretRightOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Collapse,
  Empty,
  Form,
  Input,
  List,
  Modal,
  Row,
  Typography,
  Tag
} from 'antd'
import { FlexSectionHeader } from '../style'

import { STUDENT } from '../../constants/userRoles'

import {
  createCourse,
  getAllCourses,
  deleteCourse,
  unEnroll
} from '../../reducers/courseReducer'
import Spinner from '../../components/Spinner'
import CourseCard from '../../components/CourseCard'
import { useHistory } from 'react-router'
import { getAllDeadlines } from '../../reducers/deadlinesReducer'
import { DateTime } from 'luxon'

import { FileTextOutlined } from '@ant-design/icons'
import { AiOutlineSolution } from 'react-icons/ai'

const { Title, Text } = Typography
const {confirm} = Modal
const Dashboard = () => {
  const dispatch = useDispatch()
  const history = useHistory()


  useEffect(() => {
    dispatch(getAllCourses())
    dispatch(getAllDeadlines(user._id))
  }, [dispatch])

  const user = useSelector((state) => state.auth.user)
  const courses = useSelector((state) =>
    state?.courses?.data?.filter((course) => course.status !== 'archived')
  )
  const loading = useSelector((state) => state.courses.loading)
  const deadlines = useSelector((state) => state.deadlines.data)
  const deadlinesLoading = useSelector((state) => state.deadlines.loading)

  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)

  const enrolledCourses = courses?.filter((course) => course.enrolled)

  const handleCancel = () => {
    setModalVisible(false)
  }

  const addCourse = (course) => {
    dispatch(createCourse(course))
  }

  const removeCourse = (courseId) => {
    dispatch(deleteCourse(courseId))
  }

  const handleUnenroll = (courseId, userId) => {
    dispatch(unEnroll(courseId, userId))
  }

  const handleCourseCardClick = (courseId) => {

    // const resultPromise = usersService.getUser()
    // resultPromise.then((result) => {
    
      if (user.isActive || courseId === '6594cc0d7a2e2a4f8be0b2ac') {
        history.push(`/app/course/${courseId}/modules`) 
      }
      
     if (!user.isActive && courseId !== '6594cc0d7a2e2a4f8be0b2ac') {
      confirm({
      title: '해당 과목은 활성유저만 케어가 가능합니다.',
      icon: <ExclamationCircleOutlined />,
      content:<span>활성유저 등록에 관한 상담은 <a href='https://open.kakao.com/o/sqb0EI2f' target='_blank' rel="noopener noreferrer">카카오 오픈채팅 상담 바로가기 클릭!</a></span>,
        onOk() {
        return
      },
      okText: '확인',
      cancelText: '',
      cancelButtonProps : {style : {display : 'none'}}
      })
      return
    }
    // }).catch((error) => {
    //   console.log(error)
    // })

    
  }

  if (loading) return <Spinner size="large" />

  return (
    <>
      <FlexSectionHeader>
        <Title level={3}>등록과목</Title>
        {user && user.role !== STUDENT && (
          <Button
            onClick={() => setModalVisible(true)}
            shape="square"
          >
            과목 추가
          </Button>
        )}
      </FlexSectionHeader>

      <Modal
        title="새로운 과목 추가"
        open={modalVisible}
        onOk={form.submit}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            취소
          </Button>,
          <Button key="submit" type="primary" onClick={form.submit}>
            제출
          </Button>
        ]}
      >
        <Form
          name="add Course"
          form={form}
          onFinish={addCourse}
          requiredMark={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item
            name="courseName"
            label="과목 이름"
            rules={[
              {
                required: true,
                message: '과목을 입력하세요!'
              }
            ]}
          >
            <Input placeholder="과목을 입력하세요" />
          </Form.Item>

          <Form.Item name="description" label="내용">
            <Input.TextArea placeholder="(선택)" allowClear />
          </Form.Item>

          <Form.Item name="image" label="배경이미지">
            <Input placeholder="(선택) image url" />
          </Form.Item>
        </Form>
      </Modal>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={16} xl={19}>
          <div style={{ marginTop: '8px' }}>
                <List
                  grid={{
                    gutter: 24,
                    xs: 2,
                    sm: 2,
                    md: 3,
                    lg: 3,
                    xl : 4,
                    xxl: 4
                  }}
                locale={{emptyText : "수강과목 없음"}}
                  dataSource={enrolledCourses}
                  renderItem={(course) => (
                    <List.Item>
                      <CourseCard
                        course={course}
                        removeCourse={() => removeCourse(course.id)}
                        handleUnenroll={() =>
                          handleUnenroll(course.id, user._id)
                        }
                        onClick={() => handleCourseCardClick(course.id)}
                      />
                    </List.Item>
                  )}
                />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={5}>
          <DeadlinesViewer loading={deadlinesLoading} deadlines={deadlines} />
        </Col>
      </Row>
    </>
  )
}

const DeadLinesContainer = styled.div`
  height: auto;
  width: 100%;
  box-shadow : 0px -5px 20px #c9d6dc;
  justify-content: center;
  padding : 8px;
  border-radius : 12px;
`

const DeadlineItem = styled(List.Item)`
  background-color: #fff;
  padding: 20px;
  // box-shadow : 0px -5px 20px #c9d6dc;
`

const DeadlinesViewer = (props) => {
  const { loading, deadlines } = props

  if (loading) return <Spinner size="large" />

  return (
    <DeadLinesContainer>
      <div
        style={{
          height: '70px',
          backgroundColor : "white",
          color: 'white',
          padding: '20px',
          paddingRight: '0px',
          
        }}
      >
        <Typography.Title style={{ color: 'black', borderBottom : "1px solid #dee2e6" }} level={4}>
          과제:마감시각, 시험:오픈시각
        </Typography.Title>
      </div>
      <List
        style={{ maxHeight: '445px', overflow: 'hidden', overflowY: 'auto' }}
        dataSource={deadlines.filter((item) => {
          return DateTime.fromISO(item.deadline) >= DateTime.now()
        })}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'과제없음'}
            />
          )
        }}
        renderItem={(item) => {
          const date = DateTime.fromISO(item.deadline)
          return (
            <DeadlineItem>
              <List.Item.Meta
                avatar={
                  item.type === 'Exam' ? (
                    <Tag color="red">시험</Tag>
                    // <AiOutlineSolution style={{ fontSize: '16px' }} />
                  ) : (
                    <Tag color="blue">과제</Tag>
                    // <FileTextOutlined />
                  )
                }
                title={
                  <a
                    href={`/app/course/${
                      item.course.id
                    }/${item.type.toLowerCase()}/${item.assessmentId}`}
                  >
                    {item.title}
                  </a>
                }
                description={item.course.name}
              />
              <Text style={{ width: '80px' }} type="secondary">
                {date.toLocaleString(DateTime.DATETIME_MED)}
              </Text>
            </DeadlineItem>
          )
        }}
      />
    </DeadLinesContainer>
  )
}

export default Dashboard
