import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import usersService from '../../services/users'

import { Typography, Modal, Input, List, Collapse, Button } from 'antd'
import {
  CaretRightOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import { FlexSectionHeader } from '../style'
import CourseCard from '../../components/CourseCard'

import Spinner from '../../components/Spinner'

import {
  getAllCourses,
  deleteCourse,
  enroll,
  unEnroll,
} from '../../reducers/courseReducer'


import { useHistory } from 'react-router-dom'

const { Title, Text } = Typography
const { confirm } = Modal

const Courses = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllCourses())
  }, [dispatch])



  const user = useSelector((state) => state.auth.user)
  const courses = useSelector((state) =>
    state?.courses?.data?.filter((course) => course.status !== 'archived')
  )
  const loading = useSelector((state) => state.courses.loading)
  const history = useHistory()

  const [filter, setFilter] = useState('')

  const filteredCourses = courses?.filter((course) => {
    return course.name.toLowerCase().indexOf(filter.toLowerCase()) > -1
  })

  const removeCourse = (courseId) => {
    dispatch(deleteCourse(courseId))
  }

  const handleEnroll = (courseId, userId) => {

    // const resultPromise = usersService.getUser()
    // resultPromise.then((result) => {

      if (user.isActive || courseId === '6594cc0d7a2e2a4f8be0b2ac') {
        dispatch(enroll(courseId, userId))   
      }
     if (!user.isActive && courseId !== '6594cc0d7a2e2a4f8be0b2ac') {
      confirm({
      title: '해당 과목은 활성유저만 케어가 가능합니다.',
      icon: <ExclamationCircleOutlined />,
      content:<span>활성유저 등록에 관한 상담은 <a href='https://open.kakao.com/o/sqb0EI2f' target='_blank' rel="noopener noreferrer">카카오 오픈채팅 상담 바로가기 클릭!</a></span>,
        onOk() {
        return
      },
      okText: '확인',
      cancelText: '',
      cancelButtonProps : {style : {display : 'none'}}
      })
      return
    }
    // }).catch((error) => {
      // console.log(error)
    // })

  }

  const handleUnenroll = (courseId, userId) => {
    dispatch(unEnroll(courseId, userId))
  }

  const handleSearch = (value) => {
    setFilter(value)
  }

  const confirmEnrolled = function (courseId, userId) {
    console.log('courseId', courseId)
    
    // const resultPromise = usersService.getUser()
    // resultPromise.then((result) => {
    // console.log('result', result)
    if (!user.isActive && courseId !== '6594cc0d7a2e2a4f8be0b2ac') {
        confirm({
        title: '해당 과목은 활성유저만 케어가 가능합니다.',
        icon: <ExclamationCircleOutlined />,
        content:<span>활성유저 등록에 관한 상담은 <a href='https://open.kakao.com/o/sqb0EI2f' target='_blank' rel="noopener noreferrer">카카오 오픈채팅 상담 바로가기 클릭!</a></span>,
        onOk() {
          return
        },
        okText: '확인',
        cancelText: '',
        cancelButtonProps : {style : {display : 'none'}}
        })
        return
    }
      
      confirm({
      title: '1:1 학습 케어를 원하십니까?',
      icon: <ExclamationCircleOutlined />,
      content: '과목의 목차를 열람하려면 과목등록이 필수입니다.',
      onOk() {
        handleEnroll(courseId, userId)
      },
      onCancel() {
        return
      },
      okText: '확인',
      cancelText : '취소'
      })
      

    // }).catch((error) => {
    //   console.log(error)
    // })

    
  }

  const handleCourseCardClick = (courseId, userId, userEnrolled) => {
    if (userEnrolled) history.push(`/app/course/${courseId}/modules`)
    else confirmEnrolled(courseId, userId)
  }

  const handleOnMethod = () => {
    confirm({
      title: '1:1 it 케어 서비스 입니다.',
      icon: <ExclamationCircleOutlined />,
      content: 
        <>
          <div>
            1. IT 기초지식 과목을 수강등록 합니다.<br/><br/>
          </div>
          <div>
            2. 해당 과목의 세부사항에 접속하면 드랍다운 메뉴를 포함한 헤더메뉴에서 기능들을 확인할 수 있습니다.<br/><br/>
          </div>
          <div>
            3. 특히 드랍다운 메뉴의 공지사항에 디테일하게 어떤 방식으로 학습을 해야하는지 기술되어 있습니다.<br/><br/>
          </div>
          <div>
            4. 구체적인 서비스 소개영상, 운영, 가격정책은 아래에 첨부된 링크를 참고 바랍니다.<br/><br/>
          </div>
          <div>
            5. IT 기초지식을 제외한 다른 과목들은 활성유저로 전환해야 케어가 가능합니다. 활성유저 전환이 필요하시면 카카오톡 1:1 상담 문의바랍니다.<br/><br/>
          </div>
           <div>
            <a href='https://youtu.be/RDTYJ-941r4' target='_blank' rel="noopener noreferrer">서비스 소개영상 바로가기!</a>
          </div>
          <div>
            <a href='https://adaptive-english-fad.notion.site/97c53e2d02a246f6869df748d54861a5?pvs=4' target='_blank' rel="noopener noreferrer">서비스 운영, 가격정책 바로가기!</a>
          </div>
          <div>
            <a href='https://open.kakao.com/o/sqb0EI2f' target='_blank' rel="noopener noreferrer">카카오 오픈채팅 상담 바로가기 클릭!</a>
          </div>
        </>
      ,
      onOk() {
      },
      onCancel() {
        return
      },
      cancelText: '',
      cancelButtonProps : {style : {display : 'none'}},
      okText: '확인',
    })
  }

  if (loading) return <Spinner size="large" />

  return (
    <React.Fragment>
      <FlexSectionHeader>
        <Title level={3}>개설과목 <Button onClick={handleOnMethod}>더코딩케어 사용법</Button></Title>
        <Input.Search
          allowClear
          onSearch={handleSearch}
          placeholder="검색해보세요"
          size="large"
          style={{ width: '50%', alignSelf: 'center' }}
        />
      </FlexSectionHeader>
      {/* <Button>더코딩클럽 사용법</Button> */}
      {/* <div>
        <span>
          일반인 과목을 수강신청 하면 1:1 관리 학습매니저가 바로 배정되고
          1:1 관리 메뉴에서 상담받으실 수 있습니다.
          무료체험 기간에는 일반인 과목만 체험가능하고 일부기능이 제한됩니다.
          현재는 FrontEnd(React) 과정 관리만 가능합니다.
          </span>
      </div> */}
      {/* <div>
      <Button>FrontEnd</Button>
      <Button>BackEnd</Button>
      <Button>DevOps</Button>
      <Button>Full Stack</Button>
      <Button>app Developer</Button>
      <Button>AI Developer</Button>
      <Button>BlockChanin Developer</Button>
      <Button>Game Developer</Button>
      <Button>IT창업</Button>
      <Button>프리코스</Button>
      </div> */}

      <div style={{ marginTop: '8px' }}>
        {/* <Collapse
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          defaultActiveKey={['1']}
          ghost
        >
          <Collapse.Panel header={<Text strong>개설과목</Text>} key="1"> */}
            <List
              grid={{
                gutter: 24,
                xs: 2,
                sm: 2,
                md: 3,
                lg: 3,
                xl : 4,
                xxl: 5
              }}
              dataSource={filteredCourses}
              renderItem={(course) => (
                <List.Item>
                  <CourseCard
                    course={course}
                    removeCourse={() => removeCourse(course.id)}
                    handleEnroll={() => handleEnroll(course.id, user._id)}
                    handleUnenroll={() => handleUnenroll(course.id, user._id)}
                    onClick={() =>
                      handleCourseCardClick(
                        course.id,
                        user._id,
                        course.enrolled
                      )
                    }
                  />
                </List.Item>
              )}
            />
          {/* </Collapse.Panel>
        </Collapse> */}
      </div>
    </React.Fragment>
  )
}

export default Courses
