import { Layout } from 'antd'
import Styled from 'styled-components'

const { Footer } = Layout

const S = {}

S.Footer = Styled(Footer)`
    text-align: center;
    // box-shadow: 0px -5px 20px #c9d6dc;
     background: #303740;
     color : white;
`

export default S
