import React from 'react'

export default function AppFooter() {

  const handleOnAgreement = () => {
    window.open('https://adaptive-english-fad.notion.site/937dd132b34b43c886db4f541a9f1d08?pvs=4')
  }

   const handleOnPrivacy = () => {
    window.open('https://adaptive-english-fad.notion.site/85d2a06b89ae43008ec77a9f721a336e?pvs=4')
  }
  return (
    <>
      <div style={{lineHeight : '1.6'}}>
        <div>더코딩케어</div>
        <div>사업자 등록번호 512-20-01698</div>
        <div>대표 정인용</div> 
        <div>서울시 구로구 디지털로 288, 5층 508-2호(구로동, 대륭포스트타워1차)</div> 
        <div>thecodingclub8@gmail.com</div>
        <div>2024 Ground All right reserved</div>
        <div>
          <span onClick={handleOnAgreement}>이용약관</span>&nbsp;&nbsp;&nbsp;
          <span onClick={handleOnPrivacy}>개인정보처리방침</span>
          </div>
        </div>
    </>
  )
}
