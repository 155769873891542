import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import { Form, Input, Button, Checkbox, Divider, Select, Upload, Modal } from 'antd'
import {
  MailOutlined,
  LockOutlined,
  GoogleOutlined,
  UserOutlined,
  MobileOutlined,
  CodeSandboxOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'

import { register } from '../../reducers/authReducer'

import { useDispatch } from 'react-redux'

import {
  FormItemFlex,
  PageContainer,
  FormContainer,
  FormWrapper,
  FormTitle
} from './style'

const Registeration = () => {
  const dispatch = useDispatch()
  const history = useHistory()

const { confirm } = Modal

  
  const onFinish = (values) => {
    try {
      dispatch(register(values, history))
    } catch (e) {
      console.log("회원가입 할 수 없습니다.")
    }
  }

   const handleOnAgreement = () => {
    window.open('https://adaptive-english-fad.notion.site/937dd132b34b43c886db4f541a9f1d08?pvs=4')
   }
  
  const handleOnPrivacy = () => {
    window.open('https://adaptive-english-fad.notion.site/85d2a06b89ae43008ec77a9f721a336e?pvs=4')
  }

    const handleOnMethod = () => {
    confirm({
      // title: '1:1 학습 케어 서비스 입니다.',
      icon: <ExclamationCircleOutlined />,
      content: 
        <>
          <div>
            1. 닉네임 중복되는 경우 회원가입이 안됩니다.<br/><br/>
          </div>
          <div>
            2. 이메일 중복되는 경우 회원가입이 안됩니다.<br/><br/>
          </div>
          <div>
            3. 휴대폰 번호 확인 바랍니다.<br/><br/>
          </div>
          <div>
            <a href='https://open.kakao.com/o/sqb0EI2f' target='_blank' rel="noopener noreferrer">그래도 안되시면 바로 해결해드리겠습니다!
              카카오 오픈채팅 상담 바로가기 클릭!</a>
          </div>
        </>
      ,
      onOk() {
      },
      onCancel() {
        return
      },
      cancelText: '',
      cancelButtonProps : {style : {display : 'none'}},
      okText: '확인',
    })
  }

  return (
    <PageContainer>
      <FormContainer>
        <FormWrapper>
          <Form
            name="register"
            onFinish={onFinish}
            initialValues={{ remember: false }}
            scrollToFirstError
          >
            <FormTitle>회원가입</FormTitle>
            <p>
              계정이 있으신가요? ? <Link to="/login">로그인하러 가기</Link>
            </p>
            <p>
              회원가입이 안되시나요? <span onClick={handleOnMethod} style={{cursor : 'pointer', color : '#1677ff'}}>문의하기전</span>
            </p>

            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: '이름을 적으세요.'
                },
                {
                  min: 2,
                  message: '이름은 최소 2글자 이상이어야 합니다.'
                }
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="성명" />
            </Form.Item>

            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: '닉네임을 입력하세요.'
                },
                {
                  min: 2,
                  message: '닉네임은 최소 2글자 이상이여야 합니다.'
                }
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="닉네임" />
            </Form.Item>

            <Form.Item
              name="email"
              rules={[
                {
                  type: 'email',
                  message: '적으신 이메일이 정확하지 않습니다.!'
                },
                {
                  required: true,
                  message: '이메일을 적으세요!'
                }
              ]}
            >
              <Input prefix={<MailOutlined />} placeholder="이메일 (로그인 아이디) 까먹음 주의!" />
            </Form.Item>
            <Form.Item name="role" style={{ display: 'none'}}>
              <Select prefix={<UserOutlined />} placeholder="역할"  defaultValue='student' disabled='true'>
                <Select.Option value="instructor">튜터</Select.Option>
                <Select.Option value="student">학생</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="mobile"
              rules={[
                {
                  required: true,
                  message: '휴대폰 번호를 적으세요!'
                }
              ]}
            >
              <Input prefix={<MobileOutlined />} placeholder="휴대폰번호('-'없이)" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: '비밀번호를 적으세요!'
                }
              ]}
              hasFeedback
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="비밀번호"
              />
            </Form.Item>

            <Form.Item
              name="passwordConfirm"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: '비밀번호를 확인하세요!'
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }

                    return Promise.reject(
                      '비밀번호와 비밀번호확인이 일치하지 않습니다.'
                    )
                  }
                })
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="비밀번호 확인"
              />
            </Form.Item>
            {/* <Form.Item
              name="code"
              rules={[
                {
                  required: true,
                  message: 'Please input your nickname!',
                  whitespace: true
                },
                {
                  len: 6,
                  message: 'length should be 6'
                }
              ]}
            >
              <Input prefix={<CodeSandboxOutlined />} placeholder="code" />
            </Form.Item> */}

            <FormItemFlex>
              <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject('서비스 이용에 동의하셔야 합니다.')
                  }
                ]}
              >
                <Checkbox>
                  서비스 이용에 동의합니다.
                </Checkbox>
              </Form.Item>
              {/* <Form.Item>
                <Link to="/ForgetPassword">Forget Password?</Link>
              </Form.Item> */}
              <div style={{marginTop : '5px'}}>
                  <span onClick={handleOnAgreement} style={{color : '#1677ff'}}>이용약관</span>
                  &nbsp;&nbsp;<span onClick={handleOnPrivacy} style={{color : '#1677ff'}}>개인정보처리방침</span>
              </div>
            </FormItemFlex>
                
            <Form.Item>
                <Button block type="primary" htmlType="submit">
                  회원가입
                </Button>
            </Form.Item>

            {/* <Divider plain>Or</Divider>

            <Form.Item>
              <Button block icon={<GoogleOutlined />}>
                Sign up with Google
              </Button>
            </Form.Item> */}
          </Form>
        </FormWrapper>
      </FormContainer>
    </PageContainer>
  )
}
export default Registeration
