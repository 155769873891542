import axios from 'axios'

const check = async (text) => {
  console.log('test ;;;;;', text)
  try {
    // const response = await axios.post('', { body: text })

    // console.log('response', response)
    // const res = response.data
    const res = '2'

    if (res == '1') {
      return false
    } else {
      return true
    }
  } catch (error) {
    console.log(error)
    return true
  }
}

const checkModerationService = { check }

export default checkModerationService
