import React, { useEffect } from 'react'
import { FlexSectionHeader } from '../style'
import styled from 'styled-components'

import { Typography, Button, List, Space, Tag, Dropdown, Menu } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { PlusOutlined, FileTextOutlined } from '@ant-design/icons'
import { STUDENT } from '../../constants/userRoles'
import {
  deleteAssignment,
  getAllAssignments
} from '../../reducers/assignmentReducer'
import { useHistory, useParams } from 'react-router'

import useCoursePrivilege from '../../hooks/useCourseprivilege'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'

const { Title, Text } = Typography

const Assignments = () => {
  const dispatch = useDispatch()
  const { courseId } = useParams()
  const history = useHistory()
  const { enrolled, privilege } = useCoursePrivilege()

  const assignments = useSelector((state) => state.assignments.data)
  const userId = useSelector((state) => state.auth.user._id)
  const role = useSelector((state) => state.auth.user.role)
  
  console.log('assignments', assignments)
  useEffect(() => {
    dispatch(getAllAssignments(courseId, userId, role))
  }, [courseId, dispatch])

  return (
    <>
      <FlexSectionHeader>
        <Title level={3}>과제</Title>
        {enrolled && privilege !== STUDENT && (
          <Button
            onClick={() =>
              history.push(`/app/course/${courseId}/assignments/create`)
            }
            shape="square"
          >
            과제 추가
          </Button>
        )}
      </FlexSectionHeader>

      <div style={{ marginTop: '16px' }}>
        <List
          locale={{emptyText: "과제없음"}}
          dataSource={assignments}
          renderItem={(assignment) => (
            <AssignmentItem assignment={assignment} />
          )}
        />
      </div>
    </>
  )
}

const StyledListItem = styled(List.Item)`
  background-color: #fff;
  padding: 24px 16px;
  width: 100%;
  margin-top: 16px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px -5px 20px #c9d6dc;
  &:hover {
    background-color: #eee;
  }
`
const AssignmentItem = ({ assignment, disabled }) => {
  const { courseId } = useParams()
  const { privilege } = useCoursePrivilege()
  const dispatch = useDispatch()

  const history = useHistory()

  const optionMenu = (
    <Menu>
      <Menu.Item>
        <Link to={`/app/course/${courseId}/assessment/${assignment.id}/grade`}>
          점수 매기기
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to={`/app/course/${courseId}/assessment/${assignment.id}/submissions`}
        >
          모든 제출들
        </Link>
      </Menu.Item>
      <Menu.Item
        danger
        onClick={() => dispatch(deleteAssignment(courseId, assignment.id))}
      >
        삭제
      </Menu.Item>
    </Menu>
  )

  const getActions = (privilege) => {
    if (privilege !== STUDENT)
      return (
        <>
          <Space
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            <Dropdown.Button
              trigger={['click']}
              placement="bottomLeft"
              type="text"
              overlay={optionMenu}
            ></Dropdown.Button>
          </Space>
        </>
      )
  }

  return (
    <StyledListItem
      onClick={() =>
        history.push(`/app/course/${courseId}/assignment/${assignment.id}`)
      }
      extra={getActions(privilege)}
    >
      <Space style={{padding : "12px 16px"}}>
        {/* <FileTextOutlined
          style={{
            fontSize: '32px',
            color: disabled ? '#a7a7a7d9' : 'intial'
          }}
        /> */}
        <Space size="small" direction="vertical">
          <Space>
            {/* <span>
              <Text type="secondary">중요도: </Text>
              <Text strong>{`${assignment.weight}/10`}</Text>
            </span> */}
            <span>
              <Text type="secondary">최고점수: </Text>
              <Text strong>{assignment.maxScore}</Text>
            </span>
            {/* <span>
              <Text type="secondary">Submission: </Text>
              <Text strong>{assignment.submissionType}</Text>
            </span> */}
          </Space>

          <Title
            style={{ margin: 0, color: disabled ? '#a7a7a7d9' : 'intial' }}
            level={5}
          >
            {assignment.title}
          </Title>

          <div>
            <Tag color="red">마감</Tag>
            <Text type="secondary">
              {DateTime.fromISO(assignment.dueDate).toLocaleString(
                DateTime.DATETIME_FULL
              )}
            </Text>
          </div>
        </Space>
      </Space>
    </StyledListItem>
  )
}

export default Assignments
