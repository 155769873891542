import React from 'react'
import  './cardStyle.css'
import { Button, Card, Dropdown, Menu } from 'antd'


const CourseImage = ({ image, backgroundColor, coursename }) => {


  // if (!image)
  //   return (
  //     <div style={{ backgroundColor: `${backgroundColor}`, height: 256, borderTopLeftRadius : "4px", borderTopRightRadius: "4px" }}></div>
  //   )
  
switch (coursename) {
  case 'IT 기초 지식':
    image = '/images/it.png'
    break;
  case 'HTML':
    image = '/images/html.png'
    break;
  case 'CSS':
    image = '/images/css.png'
    break;
  case 'javascript':
    image = '/images/javascript.png'
    break;
  case 'git':
    image = '/images/git.png'
    break;
  case 'React':
    image = '/images/React.png'
    break;
  case 'Typescript':
    image = '/images/Typescript.png'
    break;
  case 'Next':
    image = '/images/next.png'
    break;
  case 'Node.js':
    image = '/images/node.png'
    break;
  case 'Mysql':
    image = '/images/mysql.png'
    break;
  case 'MongoDB':
    image = '/images/mongodb.png'
    break;
  case '자료구조':
    image = '/images/dataSt.png'
    break;
  case '알고리즘':
    image = '/images/algorithm.png'
    break;
  case '데이터베이스':
    image = '/images/database.png'
    break;
  case '네트워크':
    image = '/images/network.png'
    break;
  case '컴퓨터 구조':
    image = '/images/computerSt.png'
    break;
  case '운영체제':
    image = '/images/operatingSys.png'
    break;
  case '클린코드':
    image = '/images/CleanCode.png'
    break;
  case '이력서, 포트폴리오, 면접':
    image = '/images/career.png'
    break;
  default:
    console.log('Unknown fruit.');
}

  return (
    <img
      className='CardImg'
      style={{
        // objectFit: 'cover',
        // objectPosition: 'top',
      }}
      alt="course img"
      src={image}
    />
  )
}

const CardContent = (props) => {
  const {
    description,
    enrolled,
    onEnroll,
    onUnenroll,
    loadingEnroll,
    disableEnroll
  } = props

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {description}
      <div
        onClick={(event) => {
          event.stopPropagation()
        }}
        style={{
          marginTop: '20px',
          alignSelf: 'flex-end'
        }}
      >
        {!disableEnroll && (
          <>
            {!enrolled && (
              <Button loading={loadingEnroll} onClick={() => onEnroll()}>
                과목 담기
              </Button>
            )}

            {enrolled && (
              <Button loading={loadingEnroll} onClick={() => onUnenroll()}>
                등록취소
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

const CourseCard = (props) => {
  const {
    course,
    onClick,
    handleEnroll,
    handleUnenroll,
    removeCourse,
    disableEnroll
  } = props
  const { enrolled, privilege } = course

  const loadingEnroll = course.loadingEnroll

  const optionMenu = (
    <Menu>
      {(privilege === 'instructor' || privilege === 'admin') && (
        <Menu.Item onClick={removeCourse} danger>
          과목 삭제
        </Menu.Item>
      )}
    </Menu>      
  )

  return (
    <div className='mainContainer'>
      <Card
      hoverable
      bordered={true}
      cover={
        <CourseImage
          coursename={course.name}
          image={course.image}
          backgroundColor={course.backgroundColor}
        />
      }
      onClick={onClick}
      style={{
        boxShadow: "0px -5px 20px #c9d6dc",
      }}
    >
      <Card.Meta
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {course.name}
            <span
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              {enrolled && !disableEnroll && privilege !== 'student' &&(
                <Dropdown.Button
                  placement="bottomCenter"
                  type="text"
                  overlay={optionMenu}
                ></Dropdown.Button>
              )}
            </span>
          </div>
        }
        description={
          <CardContent
            loadingEnroll={loadingEnroll}
            description={course.description}
            enrolled={enrolled}
            onEnroll={handleEnroll}
            onUnenroll={handleUnenroll}
            disableEnroll={disableEnroll}
          />
        }
      />
    </Card>
    </div>
    
  )
}

export default CourseCard
